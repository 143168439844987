<template>
  <div v-if="agentsFilters" class="fd-p-4 fd-pb-32">
    <div class="fd-text-xs fd-font-normal fd-text-theme-9 fd-mb-3">
      <label for="roles" class="fd-mb-2">{{ $t('internalAgent.roles') }}</label>
      <multiselect
        id="roles"
        select-label=""
        deselect-label=""
        label="name"
        track-by="id"
        v-model="filters.role"
        :multiple="true"
        :options="agentsFilters.role"
      />
    </div>
    <div class="fd-text-xs fd-font-normal fd-text-theme-9 fd-mb-3">
      <label for="languages" class="fd-mb-2">{{ $t('internalAgent.languages') }}</label>
      <multiselect
        id="languages"
        select-label=""
        deselect-label=""
        label="name"
        track-by="id"
        :multiple="true"
        v-model="filters.languages"
        :options="agentsFilters.language"
      />
    </div>
    <div class="fd-text-xs fd-font-normal fd-text-theme-9 fd-mb-3">
      <label for="projects" class="fd-mb-2">{{ $t('internalAgent.projects') }}</label>
      <multiselect
        id="projects"
        select-label=""
        deselect-label=""
        label="name"
        track-by="id"
        v-model="filters.project"
        :multiple="true"
        :options="agentsFilters.project"
      />
    </div>
    <div class="fd-text-xs fd-font-normal fd-text-theme-9 fd-mb-3">
      <label for="rs" class="fd-mb-2">{{ $t('internalAgent.rs') }}</label>
      <multiselect
        id="rs"
        select-label=""
        deselect-label=""
        label="name"
        track-by="id"
        v-model="filters.rs"
        :multiple="true"
        :options="agentsFilters.rs"
      />
    </div>
    <div class="fd-text-xs fd-font-normal fd-text-theme-9 fd-mb-3">
      <label for="status" class="fd-mb-2">{{ $t('internalAgent.status') }}</label>
      <multiselect
        id="status"
        select-label=""
        deselect-label=""
        v-model="filters.status"
        :options="agentsFilters.status"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  name: "InternalAgentManagementFilters",
  components: {Multiselect},
  data() {
    return {
      filters: {
        role: '',
        project: '',
        rs: '',
        status: '',
        languages: []
      }
    }
  },
  created() {
    this.$store.dispatch('internalAgent/getAgentFilters')
  },
  watch: {
    filters: {
      handler(newVal) {
        let filters = {}
        Object.keys(this.filters).forEach(key => {
          if (Array.isArray(this.filters[key])) {
            filters[key] = this.filters[key].map(item => item.name)
            return;
          }
          if (typeof this.filters[key] === 'object') {
            filters[key] = this.filters[key].name
            return;
          }
          if (this.filters[key]) {
            filters[key] = this.filters[key]
          }
        })

        return this.$emit('filters', filters)
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      agentsFilters: 'internalAgent/agentFilters'
    })
  }
}
</script>

<style scoped>

</style>