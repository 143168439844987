<template>
  <div>
    <div class="fd-w-full fd-flex fd-items-center fd-justify-between fd-mb-6">
      <span class="fd-text-lg fd-font-semibold fd-text-theme-2">{{ $t('internalAgent.listOfInternalAgent') }}</span>
      <button
          type="button"
          @click="toggleInviteSidebar"
          class="fd-flex fd-items-center fd-bg-theme-4 fd-text-white fd-rounded-lg fd-text-sm fd-font-medium fd-px-6 fd-py-2"
      >
        <span class="fd-text-lg fd-mt-px fd-mr-2 fd-font-thin">+</span>
        <span>{{ $t('internalAgents.invite') }}</span>
      </button>
    </div>
    <div class="fd-w-full fd-bg-white fd-rounded-lg fd-px-4 fd-py-6" style="min-height: 500px;">
      <Table
          :name="name"
          :total="total"
          :fields="fields"
          :items="agents"
          :hasFilter="true"
          :hasSearch="true"
          :showList="false"
          :perPage="10"
          :isBusy="isBusy"
          @changedPage="setPage"
          @setSearch="setSearch"
          @applyFilter="applyFilter"
          @showRemoveComponent="showRemoveComponent"
          @clearAllFilters="clearAllFilters"
          @showEditComponent="editAgent"
      >
        <template #filters>
          <InternalAgentManagementFilters @filters="setFilters"/>
        </template>
      </Table>
    </div>
    <ConfirmDialogue @remove="remove"/>
    <div>
      <b-sidebar
          id="invite-agents-sidebar"
          no-header
          bg-variant="white"
          shadow right lazy backdrop
          :backdrop-variant="'dark'"
      >
        <template #default="{ hide }">
          <InviteAndUpdateAgent @save="inviteAgent" @cancel="cancel" :items="agentDetails"/>
        </template>

      </b-sidebar>
    </div>
  </div>
</template>

<script>
import Table from "@/views/components/global/Table";
import InternalAgentManagementFilters from "@/views/components/internalAgents/InternalAgentManagementFilters";
import {BButton, BSidebar} from 'bootstrap-vue'
import InviteAndUpdateAgent from "@/views/components/internalAgents/InviteAndUpdateAgent";
import ConfirmDialogue from "@/views/components/global/ConfirmDialogue";
import toast from "@/mixins/toast";
import DataPreparation from "@/libs/dataPreparation";

export default {
  name: "InternalAgent",
  components: {ConfirmDialogue, InviteAndUpdateAgent, InternalAgentManagementFilters, Table, BSidebar, BButton},
  mixins: [toast],
  data() {
    return {
      loading: false,
      search: '',
      filters: '',
      itemId: null,
      queryParams: {
        per_page: 10
      }
    }
  },
  created() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', false)
    this.$store.dispatch('internalAgent/getAgentFilters')
    this.$store.dispatch('internalAgent/getAgentOptions')
    this.getData({})
  },
  watch: {
    search: {
      handler(newVal) {
        let data = {}
        if (newVal) {
          data.search = newVal
        }
        this.getData(data)
      }
    }
  },
  computed: {
    agents() {
      let agents = this.$store.getters["internalAgent/agents"]
      return agents.map(item => {
        item.name = `${item.first_name} ${item.last_name}`
        return item
      })
    },
    agentDetails() {
      return this.$store.getters["internalAgent/agentDetails"]
    },
    fields() {
      return [
        {
          key: 'name',
          label: this.$t('table.name'),
          sortable: true, sortDirection: 'desc',
          showUserPlaceholder: true,
          formatter: (value, key, item) => {
            return item.first_name + ' ' + item.last_name
          },
          thStyle: {minWidth: '50px !important'}
        },
        {key: 'role', label: this.$t('table.role'), thStyle: {minWidth: '50px !important'}},
        {key: 'languages', label: this.$t('table.languages'), thStyle: {minWidth: '50px !important'}, showCount: true},
        {key: 'projects', label: this.$t('table.projects'), thStyle: {minWidth: '50px !important'}},
        {key: 'rss', label: this.$t('table.rs'), thStyle: {minWidth: '50px !important'}},
        {
          key: 'total_sold_amount.USD',
          label: this.$t('table.totalSell'),
          formatter: (value) => {
            return DataPreparation.thousandSeparators(value)
          },
          thStyle: {minWidth: '50px !important'}
        },
        {key: 'email', label: this.$t('table.email'), thStyle: {minWidth: '50px !important'}},
        {key: 'phone', label: this.$t('table.phone'), thStyle: {minWidth: '50px !important'}},
        {key: 'status', label: this.$t('table.status'), thStyle: {minWidth: '50px !important'}},
        {key: 'actions', label: this.$t('table.actions'), class: 'text-right', thStyle: {minWidth: '50px !important'}}
      ]
    },
    name() {
      return this.$t('internalAgent.internalAgents')
    },
    total() {
      return this.$store.getters["internalAgent/agentsTotal"]
    },
    isBusy() {
      return this.loading
    }
  },
  methods: {
    getData(data) {
      this.loading = true
      this.$store.dispatch('internalAgent/getAgents', {...this.queryParams, ...data}).finally(() => {
        this.loading = false
      })
    },
    setPage(number) {
      let data = {
        number
      }
      if (this.search) {
        data.search = this.search
      }
      this.getData(data)
    },
    setSearch(search) {
      this.search = search
    },
    setFilters(filters) {
      this.filters = filters
    },
    applyFilter() {
      if (!this.filters) return

      let data = {
        filters: this.filters
      }
      if (this.search) {
        data.search = this.search
      }
      this.getData(data)
    },
    remove() {
      this.$store.dispatch('internalAgent/deleteAgent', this.itemId).then(() => {
        this.makeToast(this.$t('toastMessages.agentDeleted'))
        let data = {
          name: this.phaseName,
        }
        if (this.search) {
          data.search = this.search
        }
        if (this.filters) {
          data.filters = this.filters
        }

        this.getData(data)
      })
          .finally(() => {
            this.itemId = null
            this.toggleConfirmDialogue()
          })
    },
    showRemoveComponent(id) {
      this.itemId = id
      this.toggleConfirmDialogue()
    },
    toggleConfirmDialogue() {
      this.$root.$emit("bv::toggle::modal", "confirmDialogue");
    },
    clearAllFilters() {
      this.search = ''
      let data = {
        id: this.$route.params.id,
      }
      this.getData(data)
    },
    editAgent(id) {
      this.$store.dispatch('internalAgent/getAgentDetails', id)
      this.toggleInviteSidebar()
    },
    toggleInviteSidebar() {
      this.$root.$emit('bv::toggle::collapse', 'invite-agents-sidebar')
    },
    inviteAgent(data) {
      if (this.agentDetails) {
        this.updateAgent(data)
        return;
      }

      this.$store.dispatch('internalAgent/invite', data).then(() => {
        this.toggleInviteSidebar()
        this.getData()
      })
    },
    updateAgent(data) {
      let form = data
      form.role = form.role.name
      form.languages = form.languages.map(item => item.name)
      form.projects = form.projects.map(item => item.id)
      form.rs = form.rs.map(item => item.name)

      this.$store.dispatch('internalAgent/updateAgent', {
        id: this.agentDetails.id,
        data: form
      }).then(() => {
        this.toggleInviteSidebar()
        this.getData()
      })
    },
    cancel() {
      this.toggleInviteSidebar()
    }
  }
}
</script>

<style scoped>

</style>