<template>
  <div>
    <div class="fd-relative fd-flex fd-items-center fd-justify-between fd-p-3" style="background-color: #E9EDEB;">
      <div class="fd-flex fd-items-center">
        <span class="fd-block fd-ml-2">{{ name }}</span>
      </div>
      <div @click="$emit('cancel')" class="fd-cursor-pointer">
        <SvgLoader :name="'close'"/>
      </div>
    </div>

    <div class="fd-p-3">
      <div class="project-image fd-w-1/2 fd-mx-auto fd-mt-4 fd-h-36 fd-overflow-hidden">
        <img
            v-if="getImageUrl"
            :src="getImageUrl"
            @error="imagePlaceholder"
            alt="avatar"
            class="object-cover"
        />
        <span v-else class="fd-block fd-w-full fd-h-36 fd-flex fd-items-center fd-justify-center"
              style="background-color: #F6F5F5">
          <SvgLoader :name="'galleryIcon'"/>
        </span>
      </div>
      <span class="fd-text-xs fd-text-theme-3 fd-block fd-text-center fd-mt-2">{{
          $t('global.allowedFileTypes')
        }}</span>
      <div class="fd-flex fd-items-center fd-justify-center fd-mt-5 fd-mx-auto" style="width: 231px;">
        <input type="file" ref="file" @change="uploadFile" style="display: none">
        <b-button block variant="primary" class="fd-mr-2" @click="upload">{{ $t('global.upload') }}</b-button>
        <b-button block variant="outline-primary" @click="resetFile" style="margin-top: 0 !important;">
          {{ $t('global.reset') }}
        </b-button>
      </div>
    </div>

    <div class="fd-px-4 fd-mt-6">
      <div class="fd-text-xs fd-font-normal fd-text-theme-9 fd-mb-3">
        <label for="firstName" class="fd-mb-2">{{ $t('global.firstName') }}</label>
        <b-form-input id="firstName" v-model="form.first_name" :placeholder="$t('global.firstName')"/>
      </div>

      <div class="fd-text-xs fd-font-normal fd-text-theme-9 fd-mb-3">
        <label for="lastName" class="fd-mb-2">{{ $t('global.lastName') }}</label>
        <b-form-input id="lastName" v-model="form.last_name" :placeholder="$t('global.lastName')"/>
      </div>

      <div class="fd-text-xs fd-font-normal fd-text-theme-9 fd-mb-3">
        <label for="national_id" class="fd-mb-2">{{ $t('global.nationalId') }}</label>
        <b-form-input id="national_id" v-model="form.national_id" :placeholder="$t('global.nationalId')"/>
      </div>

      <div v-if="agentOptions" class="fd-text-xs fd-font-normal fd-text-theme-9 fd-mb-3">
        <label for="role" class="fd-mb-2">{{ $t('internalAgent.role') }}</label>
        <multiselect
            id="role"
            label="name"
            track-by="id"
            select-label=""
            deselect-label=""
            v-model="form.role"
            :options="agentOptions.roles"
        />
      </div>
      <div v-if="agentOptions" class="fd-text-xs fd-font-normal fd-text-theme-9 fd-mb-3">
        <label for="languages" class="fd-mb-2">{{ $t('internalAgent.languages') }}</label>
        <multiselect
            id="languages"
            label="name"
            track-by="code"
            :multiple="true"
            select-label=""
            deselect-label=""
            v-model="form.languages"
            :options="agentOptions.languages"
        />
      </div>
      <div v-if="agentOptions" class="fd-text-xs fd-font-normal fd-text-theme-9 fd-mb-3">
        <label for="projects" class="fd-mb-2">{{ $t('internalAgent.projects') }}</label>
        <multiselect
            id="projects"
            label="name"
            track-by="id"
            :multiple="true"
            select-label=""
            deselect-label=""
            v-model="form.projects"
            :options="agentOptions.projects"
        />
      </div>
      <div v-if="agentOptions" class="fd-text-xs fd-font-normal fd-text-theme-9 fd-mb-3">
        <label for="rs" class="fd-mb-2">{{ $t('internalAgent.rs') }}</label>
        <multiselect
            id="rs"
            label="name"
            track-by="id"
            :multiple="true"
            select-label=""
            deselect-label=""
            v-model="form.rs"
            :options="agentOptions.rss"
        />
      </div>
      <div class="fd-text-xs fd-font-normal fd-text-theme-9 fd-mb-3">
        <label for="email" class="fd-mb-2">{{ $t('global.email') }}</label>
        <b-form-input id="email" v-model="form.email" :placeholder="$t('global.email')"/>
      </div>
      <div class="fd-text-xs fd-font-normal fd-text-theme-9 fd-mb-3">
        <label for="phone" class="fd-mb-2">{{ $t('global.phone') }}</label>
        <b-form-input id="phone" v-model="form.phone" :placeholder="$t('global.phone')"/>
      </div>
    </div>

    <div class="fd-w-full fd-p-3 fd-bg-white fd-flex fd-items-center">
      <b-button @click="save" variant="primary" class="fd-w-full fd-mr-2">{{ $t('global.save') }}</b-button>
      <b-button @click="$emit('cancel')" variant="outline-danger" class="fd-w-full">{{ $t('global.cancel') }}</b-button>
    </div>
  </div>
</template>

<script>
import {BButton, BFormInput} from 'bootstrap-vue'
import Multiselect from 'vue-multiselect'
import 'vue-search-select/dist/VueSearchSelect.css'
import {mapGetters} from "vuex";

export default {
  name: "InviteAndUpdateAgent",
  components: {BButton, BFormInput, Multiselect},
  data() {
    return {
      image: null,
      newImageUrl: null,
      form: {
        first_name: '',
        last_name: '',
        national_id: '',
        role: '',
        email: '',
        phone: '',
        languages: [],
        projects: [],
        rs: []
      }
    }
  },
  props: {
    items: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    items: {
      handler(newVal) {
        this.agentOptions.roles.forEach(item => {
          if (newVal.role === item.name) {
            this.form.role = item
          }
        })
        this.agentOptions.languages.forEach(item => {
          if (newVal.languages.includes(item.name)) {
            this.form.languages.push(item)
          }
        })
        this.agentOptions.projects.forEach(item => {
          if (newVal.projects.includes(item.name)) {
            this.form.projects.push(item)
          }
        })
        this.agentOptions.rss.forEach(item => {
          if (newVal.rs.includes(item.name)) {
            this.form.rs.push(item)
          }
        })
        this.form.first_name = newVal.first_name
        this.form.last_name = newVal.last_name
        this.form.email = newVal.email
        this.form.phone = newVal.phone
      }
    }
  },
  computed: {
    ...mapGetters({
      agentOptions: 'internalAgent/agentOptions'
    }),
    name() {
      if (this.items && Object.keys(this.items).length) {
        return this.$t('global.edit')
      }
      return this.$t('internalAgents.invite')
    },
    getImageUrl() {
      if (this.newImageUrl) {
        return this.newImageUrl
      }
      if (this.items && 'thumbnail' in this.items) {
        return this.items.thumbnail
      }
      return ''
    },
    getNewImageUrl() {
      return this.newImageUrl
    }
  },
  methods: {
    save() {
      let formData = new FormData()
      if (this.image) {
        formData.append('avatar', this.image)
      }

      //     languages: [],
      //     projects: [],
      //     rs: []
      console.log(this.form.languages.map(item => item.name))
      formData.append('first_name', this.form.first_name)
      formData.append('last_name', this.form.last_name)
      formData.append('identifier', this.form.national_id)
      formData.append('phone', this.form.phone)
      formData.append('email', this.form.email)
      formData.append('role', this.form.role.name)
      for (let i = 0; i < this.form.languages.length; i++) {
        formData.append(`languages[]`, this.form.languages[i].code)
      }
      for (let i = 0; i < this.form.projects.length; i++) {
        formData.append(`projects[]`, this.form.projects[i].id)
      }
      for (let i = 0; i < this.form.rs.length; i++) {
        formData.append(`rss[]`, this.form.rs[i].id)
      }

      return this.$emit('save', formData)
    },
    upload() {
      this.$refs.file.click()
    },
    uploadFile() {
      this.image = this.$refs.file.files[0]
      this.newImageUrl = URL.createObjectURL(this.image)
    },
    resetFile() {
      this.image = null
      this.newImageUrl = null
    },
    imagePlaceholder(e) {
      e.target.src = require('@/assets/images/pages/project/user-default.jpeg')
    },
  },
  destroyed() {
    this.$store.commit('internalAgent/setAgentDetails', null)
  }
}
</script>

<style scoped>

</style>